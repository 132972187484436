/*
 * @Author: wangqs 
 * @description 全部还款
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-04-26 10:36:36
 */
<template>
  <div
    class="page-box box"
  >
    <div class="top-box box">
      <div
        v-if="$route.query.type === 'all'"
        class="text-a"
      >
        {{ parseFloat(amount || 0).toFixed(2) }}
      </div>
      <div
        v-else
        class="text-a"
      >
        {{ parseFloat(lastAmount || 0).toFixed(2) }}
      </div>
      <div class="text-b">
        需付款（元）
      </div>
      <div class="text-c">
        本次支付将收取您实际支付金额 x 0%的支付通道费，请知晓。
      </div>
    </div>

    <!-- 付款方式 -->
    <div class="pay-type box">
      <div class="title">
        付款方式
      </div>
      <div
        v-if="repayWay == 2"
        class="pay-a pay-box"
      >
        <div class="label">
          <img
            class="img-bg"
            src="@assets/img/wechat-logo.png"
            alt=""
          >
          微信支付
        </div>
        <div class="right">
          <van-radio-group v-model="radio">
            <van-radio name="2"></van-radio>
          </van-radio-group>
        </div>
      </div>
      <div
        class="pay-b pay-box"
      >
        <div class="label">
          <img
            class="img-bg"
            src="@assets/img/bank-logo.png"
            alt=""
          >
          {{ bankName || '银行卡' }}
        </div>
        <div class="right">
          <van-radio-group v-model="radio">
            <van-radio name="1"></van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>

    <!-- 按钮 -->
    <van-button
      class="submit-btn"
      color="#51B464"
      round
      type="info"
      @click="payBtn"
    >
      支 付
    </van-button>
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      radio: '2',
      repayWay: '1',
      amount: 0,
      bankName: '银行卡'
    }
  },
  computed: {
    lastAmount () {
      return this.$store.state.allLastAmount || 0
    }
  },
  created () {
    this.amount = this.$route.query.amount || 0
    this.repayWay = this.$route.query.repayWay || '1'
    if (this.repayWay != 2) {
      this.radio = '1'
    }
    this.getBankInfo()
  },
  methods: {
    async getBankInfo() {
      let url = 'bankRepayInfo'
      let repaymentIds = JSON.parse(window.localStorage.getItem('repaymentIds') || '[]')
      let repayOrderList = JSON.parse(window.localStorage.getItem('repayOrderList') || '[]')
      let data = {
        repayWay: this.$route.query.type,
      }
      if (this.$route.query.type === 'wait') {
        url = 'bankAllRepayInfo'
        data.orderNoList = repayOrderList
      } else {
        data.repaymentIds = repaymentIds
      }
      // repaymentIds
      let res = await this.$http.repayApi[url](data)
      if (res.code === 200 && res.msg.bankName) {
        this.bankName = res.msg.bankName
      }
    },
    wxPay (data) {
      let vm = this;
      function onBridgeReady(data) {
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
          appId: data.appId || 'wx6815d091476ccf20',  //公众号ID，由商户传入    
          timeStamp: data.timeStamp,   //时间戳，自1970年以来的秒数    
          nonceStr: data.nonceStr,      //随机串    
          package: data.pkg,
          signType: data.signType,     //微信签名方式：    
          paySign: data.paySign //微信签名
        },
        function(res) {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            console.log(res, '支付成功')
            vm.goHomePage()
          }
        });
      }
      if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady(data));
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(data));
        }
      } else {
        onBridgeReady(data);
      }	
    },
    goHomePage () {
      this.$toast.success('支付成功')
      window.localStorage.removeItem('repayOrderList')
      window.localStorage.removeItem('repaymentIds')
      setTimeout(() => {
        this.$router.back()
      }, 1500)
    },
    async payBtn () {
      let orderNoList = JSON.parse(window.localStorage.getItem('repayOrderList') || '[]')
      let repaymentIds = JSON.parse(window.localStorage.getItem('repaymentIds') || '[]')
      let url =  'repayWaitAll'
      let data = {
        repayWay: this.radio
      }
      if (this.$route.query.type === 'all') {
        url = 'nowBill'
        data.repaymentIds = repaymentIds
        console.log(repaymentIds, data)
      } else {
        data.orderNoList = orderNoList
      }
      
      this.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      let res =  await this.$http.repayApi[url](data)
      console.log(res, 'pay')
      if (res.code === 200) {
        this.$toast.clear()
        if (this.radio == 1) {
          this.goHomePage()
        } else {
          // 微信支付
          this.wxPay(res.msg)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  background: #f5f5f5;
  min-height: 100vh;
  .top-box {
    padding-top: 20px;
    text-align: center;
    background-color: #fff;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .text-a {
      font-size: 30px;
      font-weight: bold;
      line-height: 40px;
    }
    .text-b, .text-c {
      color: #646464;
      font-size:12px;
      line-height: 16px;
    }
  }
  .pay-type {
    background: #fff;
    margin-top: 10px;
    padding: 15px;
    .title {
      padding-bottom: 15px;
      font-size:15px;
      line-height: 16px;
    }
    .pay-box {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      .label {
        padding-left: 30px;
        float: left;
        position: relative;
        .img-bg {
          width: 18px;
          height: 18px;
          position: absolute;
          left: 0;
          top: 16px;
        }
      }
      .right {
        padding-top: 14px;
        float: right;
      }
    }
  }
  .submit-btn {
    width: 345px;
    display: block;
    margin: 30px auto 0;
  }
}
</style>
